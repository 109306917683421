import React, { useState, useRef , useEffect} from "react";
import "./Esquire.css";

const Esquire = ()=>{
  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);
    return (
        <div className="Esquire">
            <h3 className={` fade-in ${isVisible ? "show" : ""}`}
          ref={roadmapRef}>Guided by a <span>Global Esquire</span></h3>
            <p className={` fade-in ${isVisible ? "show" : ""}`}
          ref={roadmapRef}>Following a career that spans continents and a track record advising governments, businesses, and high-profile clients, <span>Attorney-Instructor Tareian King, ESQ</span> brings unmatched expertise in navigating the complexities of Africa’s legal landscape. Her deep understanding of African legal frameworks, international business law, and global relocation strategies makes her the ideal mentor to guide you through the intricacies of establishing residency, launching businesses, or investing across the continent.</p>
            <p >As a Global Esquire with extensive experience working on the ground in Africa and the U.S., <span>Attorney King</span> provides not just instruction, but mentorship, giving you insights drawn from her prestigious career. By joining this course, you gain access to <span>Attorney King’s</span> wealth of knowledge, and you’ll continue to benefit from her guidance long after the program concludes with <span>exclusive post-course access</span> to her expertise.</p>
            <p >This course isn’t just a learning opportunity—it’s a chance to be mentored by a global legal leader, ensuring your journey across Africa’s legal landscape is informed, strategic, and successful. From mastering legal frameworks to making well-informed business decisions, this course will equip you with the knowledge and mentorship to achieve your goals.</p>
        </div>
    )
}
export default Esquire;