import React from "react";
import "./SectionFive.css";
// import {IoLocationSharp, MdEmail,FaPhoneAlt } from "react-icons/md";
import {  FaTwitter , FaLinkedin } from "react-icons/fa";
import {  IoLogoFacebook  } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Links">
        {/* <div className="linkIcons">
          <span>
          <IoLogoFacebook />
          </span>
          <span>
          <FaTwitter />
          </span>
          <span>
            <FaLinkedin />
          </span>
        </div> */}
        <p>legalseminar All Rights Reserved</p>
        <p>Privacy Policy | Terms of Service</p>
      </div>
    </div>
  );
};

export default Footer;
