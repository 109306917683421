import React from "react";
import "./Testimonials.css";
import { BsQuote } from "react-icons/bs";
import TestimonialVideoImage from "../../assist/TestimonialVideoImage.png";
import MalajaJImage from "../../assist/MalajaJImage.png";
import RenataBImage from "../../assist/RenataBImage.png";
import Testvideo1Image from "../../assist/Testvideo1Image.png";
import Testvideo1 from "../../assist/testvideo1.mp4";
import MalajaJ from "../../assist/MalajaJ.mp4";
import RenataB from "../../assist/RenataB.mp4";
import TestimonialVideo from "../../assist/TestimonialVideo.mp4";

const Results = [
  {
    userName: "Derrick J",
    // ceo: "CEO of Buzz",
    image: TestimonialVideoImage,
    video: TestimonialVideo,
  },                                        
  {
    userName: "Yolanda G",
    // ceo: "CEO of Buzz",
    image: RenataBImage,
    video: Testvideo1,
  },
  {
    userName: "Malaja J",
    // ceo: "CEO of Buzz",
    image: MalajaJImage,
    video: MalajaJ,
  },
  {
    userName: "Renata B",
    // ceo: "CEO of Buzz",
    image: Testvideo1Image,
    video: RenataB,
  },
];

const Testimonials = () => {
  return (
    <div className="Testimonials">
      <h3>
        <span></span>Testimonials
      </h3>
      <h4>
        Client Testimonials: Real <br /> Results, Real Feedback
      </h4>
      <div className="cards">
        {Results.map((data, index) => (
          <div className="card" key={index}>
            <span>
              <BsQuote />
            </span>
            <div className="video">
              <div className="image-container">
                <video className="backgroundVideo" controls>
                  <source src={data.video} type="video/mp4" />
                </video>
              </div>
              <div className="imgInfo">
                <div className="name">
                  <h2>{data.userName}</h2>
                  {/* <p>{data.ceo}</p> */}
                </div>
                {/* <img src={data.image} alt={data.userName} /> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
