import React, { useState, useRef , useEffect} from "react";
import "./SectionTwo.css";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const faqs = [
  {
    number: "01",
    heading:
      "Introduction to African Relocation: Legal Frameworks and Key Concepts",
    headingTwo:
      " Week 1: Introduction to African Relocation: Legal Frameworks and Key Concepts",
    content:
      "In Week 1, we’ll explore the foundational elements of relocating to Africa. You’ll gain an understanding of the continent’s diverse geography and regions, including how climate, infrastructure, and local governance might impact your relocation or business decisions. We’ll also introduce you to Africa’s varied legal systems—civil law, common law, customary law, and Sharia law—so you can understand how legal structures vary across different countries. This week will cover essential legal concepts, such as residency requirements, property rights, and business regulations, giving you the knowledge you need to begin planning your move or business venture with clarity and confidence.",
  },
  {
    number: "02",
    heading:
      "Pre-Arrival Considerations: Cultural Dynamics and Practical Steps",

    headingTwo:
      "Week 2: Pre-Arrival Considerations: Cultural Dynamics and Practical Steps:",
    content:
      "In Week 2, we’ll focus on the practical steps required before you move. You’ll explore Africa’s rich cultural diversity and societal norms, learning how to navigate expectations and customs in your chosen destination. We’ll cover key pre-arrival steps, including financial planning (such as currency exchange, banking options, and taxation), document preparation, and healthcare requirements (including vaccinations and medical insurance). We’ll also delve into safety and security considerations, giving you the tools to evaluate potential countries based on factors like political stability, crime rates, and the local legal framework. By the end of this week, you’ll have a complete roadmap for your pre-arrival preparations.",
  },
  {
    number: "03",
    heading: "From Visitor to Resident: Visas, Residency, and Citizenship",

    headingTwo:
      " Week 3: From Visitor to Resident: Visas, Residency, and Citizenship:",
    content:
      "In Week 3, we’ll guide you through the transition from visitor to resident in Africa. You’ll receive in-depth information on the visa and residency processes for key African countries, including tourist, business, and investor visa types. We’ll provide a step-by-step guide on how to apply for residency and explore options for obtaining permanent residency and citizenship, including pathways to dual citizenship. We’ll also use real-world examples and case studies to show how others have successfully navigated this process, helping you avoid common pitfalls. By the end of this week, you’ll be equipped with the knowledge to secure long-term residency or citizenship in your selected country.",
  },
  {
    number: "04",
    heading: "Thriving Financially: Business, Real Estate, and Partnerships",

    headingTwo:
      " Week 4: Thriving Financially: Business, Real Estate, and Partnerships:",
    content:
      "Week 4 will focus on building financial success in Africa, whether you’re setting up a business or investing in real estate. You’ll learn how to legally register a business in your chosen country, covering tax regulations, local labor laws, and required permits. We’ll provide an in-depth look at real estate investment, including how to purchase property as a foreigner, legal protections, and property rights. Additionally, you’ll explore strategic partnerships and joint ventures with local businesses, learning how to structure these partnerships to comply with local regulations while maximizing profitability. By the end of this week, you’ll have a clear understanding of how to build a thriving business or investment portfolio in Africa.",
  },
  {
    number: "05",
    heading:
      "Living and Loving in Africa: Family Law, Cultural Integration, and U.S. Immigration",

    headingTwo:
      "Week 5: Living and Loving in Africa: Family Law, Cultural Integration, and U.S. Immigration:",
    content:
      "In Week 5, we’ll explore the legal aspects of family life in Africa, from marriage and childbirth to custody laws and dual citizenship for children. You’ll learn how to handle legal matters such as spousal rights, divorce, and inheritance, as well as how to navigate family law systems in your chosen country. We’ll also address how to apply for dual citizenship for children born abroad. Additionally, we’ll guide you through the U.S. immigration system, helping you secure American residency and citizenship for your spouse. By the end of this week, you’ll have a solid family integration plan that addresses both legal and cultural challenges across both African and U.S. legal systems",
  },
  {
    number: "06",
    heading:
      "When Things Go Wrong: Legal Preparedness, Crime, Wills, Trusts, and Death",

    headingTwo:
      "Week 6: When Things Go Wrong: Legal Preparedness, Crime, Wills, Trusts, and Death",
    content:
      "In the final week, we’ll cover how to handle unexpected legal challenges in Africa, including navigating criminal laws and managing legal disputes. You’ll learn about the legal protections available to you in case of crime, such as theft, fraud, or personal injury, and how to work with local legal systems to resolve disputes. We’ll also delve into estate planning, including how to create wills and trusts that are recognized in your chosen country. You’ll learn how to ensure that your assets are protected and passed down according to your wishes. Additionally, we’ll cover the legal steps to take in case of death, helping you prepare for any emergencies that may arise during your time in Africa. By the end of this week, you’ll have a comprehensive risk management plan that safeguards your family, assets, and business.",
  },
];

const SectionTwo = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null);
  // Function to toggle the FAQ content visibility
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);

  return (
    <div className="SectionTwoMain">
      <div className="SectionTwo">
      <div className={`Roadmap fade-in ${isVisible ? "show" : ""}`}
          ref={roadmapRef}>
        <h2>
          <span>Your Legal Roadmap</span> to Success
        </h2>
        <p>
          This six-week course will empower you with the legal knowledge and
          practical skills to confidently relocate, invest, or start a business
          in Africa, setting you up for success in ways you never thought
          possible. At Africa Legal Advisory, our attorneys understand the
          diverse needs of our participants, and we’ve carefully tailored this
          program to provide you with the expertise you need to navigate
          Africa’s legal landscape. Whether you’re looking to secure residency,
          make informed investments, or establish a thriving business, this
          comprehensive course offers the strategies, tools, and support to turn
          your goals into reality.
        </p>
        <p>
          By the end of this course, you’ll have the clarity, confidence, and
          legal foundation to create a future of freedom and opportunity on the
          continent.
        </p>
      </div>

      <div className="FAQs">
        {faqs.map((faq, index) => (
          <div
            className={`card ${activeIndex === index ? "active" : ""}`}
            key={index}
          >
            <div className="card-header" onClick={() => toggleFAQ(index)}>
              <span>{faq.number}</span>
              <p className="para">
                {activeIndex === index ? faq.headingTwo : faq.heading}
              </p>
              {activeIndex === index ? <RxCross2 /> : <FaPlus />}
            </div>
            {activeIndex === index && (
              <div className="card-content">
                <p>{faq.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SectionTwo;
