import React from 'react';
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import FormsOne from './components/LandingPage/Forms/FormsOne';
import ParallaxTimeline from "./components/LandingPage/ParallaxTimeline/Animation.jsx";

const App = () => {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<PrivateRoute component={<LandingPageWithSideBar />} />}/>
          <Route path='/ParallaxTimeline' element={<PrivateRoute component={<ParallaxTimelineWithSideBar />} />}/>
          <Route path='/FormsOne' element={<PrivateRoute component={<FormsOne />} />}/>
        </Routes>
      </Router>
    </>
  )
}
function PrivateRoute({ component }) {
  const isAuthenticated = true;

  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/" replace />;
  }
}
function LandingPageWithSideBar() {
  return (
    <div className="appMain">
      <LandingPage/>
    </div>
  );
}
function ParallaxTimelineWithSideBar() {
  return (
    <div className="appMain">
      <ParallaxTimeline/>
    </div>
  );
}

export default App