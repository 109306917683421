import React, { useState } from "react";
import "./Forms.css";
import NavBar from "../../NavBar/NavBar";
import SectionFive from "../SectionFive/Footer";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

const FormsOne = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);

  const handleNext = () => {
    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="FormsOne">
      <NavBar />
      <div className="FormsOneMain">
        <div className="backgroundImage">
          <h2>Application Form: AFRICA LEGAL ADVISORY</h2>
          <p>
            Thank you for your interest in AFRICA LEGAL ADVISORY: A Legal Primer
            on Residency and Business in Africa. Please complete the application
            form below. We carefully review each application to ensure a strong
            fit between participants and the course content. Selected applicants
            will be contacted with further details.
          </p>
        </div>

        {/* Form 1: Personal Data */}
        {currentStep === 1 && (
          <div className="form">
            <span className="span">
              Personal Data <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <p>
              Note: If you are completing an application on someone else's
              behalf, you must be logged in as that person. If you are not the
              person specified above or below, please follow this{" "}
              <span>link to log out.</span>
            </p>
            <form>
              <div className="input">
                <div className="name">
                  <label htmlFor="">Full Name</label>
                  <input type="text" placeholder="Your Full Name" required />
                </div>
                <div className="name">
                  <label htmlFor="">Email Address</label>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    required
                  />
                </div>
              </div>
              <div className="name">
                <label htmlFor="">Phone Number</label>
                <input type="number" placeholder="Your Phone Number" required />
              </div>
              <div className="input">
                <div className="name">
                  <label htmlFor="">Country of Residence</label>
                  <input
                    type="text"
                    placeholder="Your Country of Residence"
                    required
                  />
                </div>
                <div className="name">
                  <label htmlFor="">Country of Residence</label>
                  <input
                    type="text"
                    placeholder="Your Country of Residence"
                    required
                  />
                </div>
              </div>
              <div className="name">
                <label htmlFor="">
                  Preferred Country of Focus in Africa (for Relocation or
                  Business)
                </label>
                <input
                  type="text"
                  placeholder="Your Preferred Country"
                  required
                />
              </div>
              <div className="btns">
                <Link to="/">
                  <button type="button">Exit</button>
                </Link>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 2: Professional Data */}
        {currentStep === 2 && (
          <div className="form">
            <span className="span">
              Professional Data <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Professional Background</h3>
            <form>
              <div className="name">
                <label htmlFor="">Current Occupation</label>
                <input
                  type="text"
                  placeholder="Your Current Occupation"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">Describe Your Professional Experience</label>
                <input
                  type="text"
                  placeholder="[Please provide a brief summary of your professional background.]"
                  required
                />
              </div>
              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 3: Interest in Africa */}
        {currentStep === 3 && (
          <div className="form">
            <span className="span">
              Interest in Africa <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Interest in Africa</h3>
            <form>
              <div className="name">
                <label htmlFor="">
                  What initially sparked your interest in Africa?
                </label>
                <input
                  type="text"
                  placeholder="[Describe what first drew your attention to Africa.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  Are you primarily interested in relocating or doing business?
                </label>
                <input
                  type="text"
                  placeholder="[Relocating, Doing Business, Both]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  Have you visited Africa or your country of interest?
                </label>
                <input type="text" placeholder="[Yes/No]" required />
              </div>
              <div className="name">
                <label htmlFor="">
                  If yes, please describe your experience
                </label>
                <input
                  type="text"
                  placeholder="[Share details about your visit(s), including the duration, purpose, and any significant insights gained.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  Do you currently know anyone in Africa?
                </label>
                <input type="text" placeholder="[Yes/No]" required />
              </div>
              <div className="name">
                <label htmlFor="">
                  If yes, please elaborate on your connection(s)
                </label>
                <input
                  type="text"
                  placeholder="[Provide details about your relationships or connections in Africa, such as family, friends, business contacts, or community organizations.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  What attracts you to the specific country you wish to focus on
                  (for relocation or business)?
                </label>
                <input
                  type="text"
                  placeholder="[Explain what appeals to you about your chosen country, whether it’s cultural, economic, environmental, or another factor.]"
                  required
                />
              </div>
              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 4: Financial and Business Goals */}
        {currentStep === 4 && (
          <div className="form">
            <span className="span">
              Financial and Business Goals <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Financial and Business Goals</h3>
            <form>
              <div className="name">
                <label htmlFor="">
                  What are your primary financial or business goals?
                </label>
                <input
                  type="text"
                  placeholder="[Please describe your financial objectives.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  Do you have a specific business venture in mind?
                </label>
                <input type="text" placeholder="[Yes/No]" required />
              </div>
              <div className="name">
                <label htmlFor="">
                  If yes, please provide an overview of your plan.
                </label>
                <input
                  type="text"
                  placeholder="[Share the details of your business or investment idea, including the industry, target market, and how you plan to achieve success.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  What is your expected timeline for achieving your financial or
                  business goals?
                </label>
                <input
                  type="text"
                  placeholder="[Indicate whether you have a short-term or long-term plan and the milestones you aim to reach.]"
                  required
                />
              </div>
              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 5: Legal and Business Experience */}
        {currentStep === 5 && (
          <div className="form">
            <span className="span">
              Legal and Business Experience <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Legal and Business Experience</h3>
            <form>
              <div className="name">
                <label htmlFor="">
                   Do you have any prior experience with African legal systems
                  or business environments?
                </label>
                <input type="text" placeholder="[Yes/No]" required />
              </div>
              <div className="name">
                <label htmlFor="">
                  If yes, please describe your experience
                </label>
                <input
                  type="text"
                  placeholder="[Provide details about any previous interactions with African legal systems, business ventures, or related activities.]"
                  required
                />
              </div>
              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 6: Course Expectations */}
        {currentStep === 6 && (
          <div className="form">
            <span className="span">
              Course Expectations <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Course Expectations</h3>
            <form>
              <div className="name">
                <label htmlFor="">
                  What are you hoping to gain from this course?
                </label>
                <input
                  type="text"
                  placeholder="[Please describe the specific knowledge, skills, or outcomes you want to achieve by completing this course.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  How do you plan to apply what you learn in this course to your
                  relocation or business goals?
                </label>
                <input
                  type="text"
                  placeholder="[Explain how you intend to use the information and skills gained from the course in your practical plans.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  What challenges do you foresee in your relocation or business
                  venture, and how do you expect this course to help you address
                  them?
                </label>
                <input
                  type="text"
                  placeholder="[Discuss any potential obstacles you anticipate and how you believe the course will help you overcome them.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                  This course is a legal primer, but it is designed to be easily
                  understood by non-lawyers. How comfortable are you with legal
                  topics?
                </label>
                <input
                  type="text"
                  placeholder="[Very Comfortable, Somewhat Comfortable, Not Comfortable]"
                  required
                />
              </div>
              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Form 7: Commitment and Availability */}
        {currentStep === 7 && (
          <div className="form">
            <span className="span">
              Commitment and Availability <IoIosArrowForward />
            </span>
            <h3>Application for User 1 (user1@gmail.com)</h3>
            <h5>December 2024</h5>
            <h3>Commitment and Availability</h3>
            <form>
              <div className="name">
                <label htmlFor="">
                  This course requires a commitment to weekly office hours and
                  live sessions over seven weeks. Are you able to commit to this
                  schedule?
                </label>
                <input type="text" placeholder="[Yes/No]" required />
              </div>
              <div className="name">
                <label htmlFor="">
                  What time zone are you in, and what are your preferred times
                  for office hours?
                </label>
                <input
                  type="text"
                  placeholder="[Please indicate your time zone and preferred times for one-on-one sessions.]"
                  required
                />
              </div>
              <h3 className="Information">
                Questions and Additional Information
              </h3>
              <div className="name">
                <label htmlFor="">
                   This course requires a commitment to weekly office hours and
                  live sessions over seven weeks. Are you able to commit to this
                  schedule?
                </label>
                <input
                  type="text"
                  placeholder="[Please list any questions you have about the course content, logistics, or anything related to your relocation or business plans.]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">How did you hear about this course?</label>
                <input
                  type="text"
                  placeholder="[Referral, Social Media, Website, Other]"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="">
                   Is there any additional information you would like to share
                  with us?
                </label>
                <input
                  type="text"
                  placeholder="[Please use this space to provide any further details that you believe are relevant to your application.]"
                  required
                />
              </div>
              <h3 className="Information">Agreement</h3>
              <div className="para">
                <h6 className="checkbox" onClick={handleCheckboxClick}>
                  {isChecked && <FaCheck />}
                </h6>
                <p>
                  By submitting this application, I confirm that the information
                  provided is accurate to the best of my knowledge and that I am
                  committed to fully participating in this course if selected.
                </p>
              </div>

              <div className="btns">
                <button type="button" onClick={handlePrevious}>
                  Previous
                </button>
                <Link to="/">
                  <button type="button">Submit</button>
                </Link>
              </div>
            </form>
          </div>
        )}
      </div>
      <SectionFive />
    </div>
  );
};

export default FormsOne;
