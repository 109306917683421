
import React, { useEffect, useRef, useState } from "react";
import "./Animation.css";
import { WiTime3 } from "react-icons/wi";
import { PiCircleNotchBold } from "react-icons/pi";
import { FaArrowRight,FaArrowLeft  } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavBar from "../../NavBar/NavBar";
import backGroundImage1 from "../../assist/backGroundImage1.png";
import backGroundImage2 from "../../assist/backGroundImage2.png";
import backGroundImage3 from "../../assist/backGroundImage3.png";
import backGroundImage4 from "../../assist/backGroundImage4.png";
import backGroundImage5 from "../../assist/backGroundImage5.png";
import backGroundImage6 from "../../assist/backGroundImage6.png";
import backGroundImage7 from "../../assist/backGroundImage7.png";
import backGroundImage8 from "../../assist/backGroundImage8.png";
import backGroundImage9 from "../../assist/backGroundImage9.png";
import backGroundImage10 from "../../assist/backGroundImage10.png";
import backGroundImage11 from "../../assist/backGroundImage11.png";
import backGroundImage12 from "../../assist/backGroundImage12.png";
import backGroundImage13 from "../../assist/backGroundImage13.png";
import backGroundImage14 from "../../assist/backGroundImage14.png";

const textData = {
  2014: [
    {
      image: backGroundImage1,
      activeText : "active",
      title: "Trained by Nelson Mandela's Attorney - George Bizos, Johannesburg, South Africa",
      description:
        "Honored to be trained by George Bizos at The Legal Resources Centre in Johannesburg, South Africa, focusing on advancing international legal principles and economic justice for local South Africans.",
    },
  ],
  2015: [
    {
      image: backGroundImage2,
      activeText : "activeOne",
      title: "International Human Rights - Uganda, East Africa",
        description:
        "Led initiatives on impact investing in Uganda, developed human rights frameworks, and conducted in-depth legal analysis of microcredit loans for local Ugandan women. Empowered female entrepreneurs through strategic workshops on financial management and grant writing.",
    },
  ],
  2016: [
    {
      image: backGroundImage3,
      activeText : "activeTwo",
      title: "International Sustainable Development - Accra, Ghana, West Africa",
      description:
        "Played a pivotal role in advocating for sustainable development in Ghana, with a focus on meticulously analyzing the country's GDP and budget allocations related to imports and exports, contributing to the nation’s economic strategies.",
    },
  ],
  2017: [
    {
      image: backGroundImage4,
      activeText : "activeThree",
      title: "Eastern US Regional Leader to Innovate Africa - Sub-Saharan Markets",
      description:
        "Executed comprehensive legal due diligence on business opportunities across Sub-Saharan Africa, and spearheaded market studies and business analysis, positioning companies for success in emerging markets.",
    },
  ],
  2018: [
    {
      image: backGroundImage5,
      activeText : "activeFour",
      title: "Juris Doctorate Certification in International Law Candidate & Legal Advisor to the Permanent Mission of Senegal",
      description:
        "While pursuing advanced legal studies, provided high-level advisory services to the Permanent Mission of Senegal, offering expert counsel on international law, including import-export regulations, transport logistics, and agricultural policies in West Africa.",
    },
  ],
  2019: [
    {
      image: backGroundImage6,
      activeText : "activeFive",
      title: " International Law Committee Member, New York City Bar Association",
      description: "Selected to join the prestigious International Law Committee at the New York City Bar Association, contributing to the discourse on global legal issues.",
    },
    {
      image: backGroundImage7,
      activeText : "activeSix",
      title: "DLA Piper Africa, Senegal Office",
      description: "Engaged in sophisticated legal work on OHADA investments, complex contracts, real estate transactions, and business and immigration cases in one of the leading legal firms in Africa.",
    },
    {
      image: backGroundImage8,
      activeText : "activeSeven",
      title: "Permanent Resident of Senegal",
      description: "Achieved permanent residency in Senegal, further solidifying deep professional and personal ties to the continent.",
    },
  ],
  2020: [
    {
      image: backGroundImage9,
      activeText : "activeEight",
      title: "Africa Fellow at Council on Foreign Relations and Open Society Foundations",
      description:
        "Undertook critical anti-money laundering investigations, collaborated with African ambassadors on pivotal US-Africa diplomacy, and published influential articles on Africa’s economic and social landscape, including “Africans Should Fight for DACA Too,” “Africa Remains Untapped Market for Booming Black Businesses in America,” “Ghana Looks to Long Relationship With African Americans for Investment,” and “How Remittances From Petit Senegal, a Diaspora Community in New York City, Build Wealth Abroad.",
    },
  ],
  2021: [
    {
      image: backGroundImage10,
      activeText : "activeNine",
      title: "African Affairs Committee, New York Bar Association",
      description: "Played an instrumental role in assisting over 20 Black Americans in relocating to Sub-Saharan Africa and establishing successful business ventures.",
    },
    {
      image: backGroundImage11,
      activeText : "activeTen",
      title: "Compliance Advisory for United States Securities and Exchange Commission",
      description: "Provided top-tier advisory services on U.S. compliance regulations to hedge funds, private equity firms, and venture capital, ensuring adherence to the highest standards of financial governance.",
    },
  ],
  2022: [
    {
      image: backGroundImage12,
      activeText : "activeEleven",
      title: "Securities Counsel for American Venture Capital Firm Investing in Africa",
      description:
        "Managed and advised on over $386 million USD in strategic investments into Africa, driving economic growth and fostering development across the continent.",
    },
  ],
  2023: [
    {
      image: backGroundImage13,
      activeText : "activeTwelve",
      title: "Securities Certification from Cornell Law School",
      description: "Earned a prestigious Securities Certification from Cornell Law School to enhance support for tech founders in Africa and to serve investors and angel investors with cutting-edge expertise in the African market.",
    },
  ],
  2024: [
    {
      image: backGroundImage14,
      activeText : "activeThirteen",
      title: "Opened International Law Firm Global Esquire and Launched Africa Legal Advisory",
      description: "Founded Global Esquire Law Firm to champion legal empowerment for individuals across the USA and Africa, and launched Africa Legal Advisory to provide clients with unparalleled resources and the ability to navigate legal challenges independently.",
    },
  ],
};

const yearsArray = [2014,2015,2016,2017,2018, 2019,2020, 2021,2022, 2023, 2024];

const Animation = () => {
  const [isShadowVisible, setShadowVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); 
  const [activeSlide, setActiveSlide] = useState(0); 
  const [text, setText] = useState(textData[yearsArray[0]][0]);
  const [scrollToActive, setScrollToActive] = useState(false);
  const scrollToRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollToRef.current) {
        const scrollElement = scrollToRef.current;
        const viewportHeight = scrollElement.clientHeight;
        const triggerPoint = viewportHeight * 0.4;

        setShadowVisible(true);

        if (scrollElement.scrollTimeout) {
          clearTimeout(scrollElement.scrollTimeout);
        }

        scrollElement.scrollTimeout = setTimeout(() => {
          setShadowVisible(false);
        }, 200);

        const years = scrollElement.querySelectorAll(".dot");

        years.forEach((year, index) => {
          const yearTop = year.getBoundingClientRect().top - scrollElement.getBoundingClientRect().top;

          if (yearTop < triggerPoint && yearTop + year.clientHeight > triggerPoint) {
            setActiveIndex(index);
            setText(textData[yearsArray[index]][0] || textData[yearsArray[0]][0]);
            setActiveSlide(0);
          }
        });
      }
    };

    const scrollElement = scrollToRef.current;

    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
        if (scrollElement.scrollTimeout) {
          clearTimeout(scrollElement.scrollTimeout);
        }
      }
    };
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
    setText(textData[yearsArray[index]][0]);
    setActiveSlide(0);
  };

  const handleSlideChange = (direction) => {
    const currentYearData = textData[yearsArray[activeIndex]];
    let newSlide;
  
    if (direction === "next") {
      newSlide = activeSlide < currentYearData.length - 1 ? activeSlide + 1 : 0;
    } else {
      newSlide = activeSlide > 0 ? activeSlide - 1 : currentYearData.length - 1;
    }
  
    // Update the activeSlide and the text based on the new slide value
    setActiveSlide(newSlide);
    setText(currentYearData[newSlide]);
  };
  

  return (
    <div className="Parallax">
      
      {activeIndex === 0 && ( 
        <div className="Timeline">
          <NavBar />
        </div>
      )}

      <div className="ParallaxTimeline">
        <div className="mainSection">
          <div className="mainParallax">
            <img src={text.image} alt="bgImage" className={text.activeText} />
            <div className="time">
              <div className="explore">
                <p>
                  <WiTime3 /> Time
                </p>
                <p>
                  <PiCircleNotchBold /> Explore
                </p>
              </div>
              <h5>Time Mode</h5>
            </div>

            <div
              className={`scrollTo ${scrollToActive ? "active" : ""}`}
              ref={scrollToRef}
            >
              <div className={`lines ${isShadowVisible ? "shadow" : ""}`}>
                {yearsArray.map((year, idx) => (
                  <div
                    className={`dot ${activeIndex === idx ? "active" : ""}`}
                    key={idx}
                  >
                    <p>{year}</p>
                    <span className="span" onClick={() => handleClick(idx)}>
                      <span></span>
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="textInfo">
              <div className="mainText">
                {textData[yearsArray[activeIndex]].length > 1 && (
                  <div className="slider">
                    <button onClick={() => handleSlideChange("prev")}><FaArrowLeft /></button>
                    <span>
                      {activeSlide + 1} of {textData[yearsArray[activeIndex]].length}
                    </span>
                    <button onClick={() => handleSlideChange("next")}><FaArrowRight /></button>
                  </div>
                )}
                <h3 className={text.activeText}>{text.title}</h3>
                <p className={text.activeText}>{text.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Animation;