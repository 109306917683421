import React from 'react';
import './NavBar.css';
import { NavLink } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import logo from '../assist/logo.png';

const NavBar = () => {
  return (
    <div className="NavBar">
      <nav>
        <img src={logo} alt="logo" />
        <div className="linksNav">
          <NavLink 
            to="/" 
            className={({ isActive }) => (isActive ? 'activeLink' : '')}
          >
            Home
          </NavLink>
          <NavLink 
            to="/ParallaxTimeline" 
            className={({ isActive }) => (isActive ? 'activeLink' : '')} 
          >
            Track Record
          </NavLink>
        </div>
        <Link to="/FormsOne"><button>Apply Now</button></Link>
      </nav>
    </div>
  );
};

export default NavBar;
