import { useState, useEffect, useRef  } from "react";
import "./Primer.css";
import { GoCheckCircleFill } from "react-icons/go";
import { Link } from "react-router-dom";



const Primer = () => {
  const [isPro, setIsPro] = useState(false);

  const handleToggle = () => {
    setIsPro((prevState) => !prevState);
  };



  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null); // Create a reference using useRef

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.5 } // Adjust threshold to 50% of element visibility
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);

  const cardTow = [
    {
      Pay: "Pay in Full",
      Get: "Get 10% Off In Full Pay",
      dollar: `${isPro ? "3500" : "1900"}`,
      Weeks: "One and Done",
    },
    {
      Pay: "Payment Plan #2",
      Get: "Installments",
      dollar: `${isPro ? "1750" : "950"}`,
      Weeks: "50% Now 50% In30Days",
    },
    {
      Pay: "Payment Plan #3",
      Get: "6 Weekly Payments",
      dollar: `${isPro ? "584" : "317"}`,
      Weeks: "Every 2 Weeks",
    },
  ];

  return (
    <div className="Primer">
      <div className="mainPrimer">
        <h6  className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>Are you ready to join us?</h6>
        <h2  className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>
          A Legal Primer on Residency and <span>Business in Africa</span>
        </h2>
        <p  className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>
          {isPro ? "For those who enroll in the Premium Primer package, you will gain exclusive access to the Africa Legal Alliance. This premium membership connects you to a network of trusted professionals across the continent, including:" : "For those who enroll in the Standard package, you will gain exclusive access to the Standard Legal Access. This Standard membership connects you to a network of trusted professionals across the continent, including: "}
        </p>

        <div className="switch-container">
          <span className={!isPro ? "active-label" : ""}>Standard</span>
          <label className="switch">
            <input type="checkbox" checked={isPro} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
          <span className={isPro ? "active-label" : ""}>Pro</span>
        </div>

        <div className={isPro ? "activeCards" : "cards"}>
          <div className="card">
            <h3>{isPro ? "Premium Primer " : "Standard"}</h3>
            <h4>
              {isPro ? "Premium Legal Experience" : "Standard Legal Experience"}
            </h4>
            <h1>
              <span>{isPro ? "$3,500" : "$1,900"}</span>
            </h1>
            <p className="line-through">
              {isPro ? "Usually $5,000" : "Usually $3,500"}
            </p>
            <h5>{isPro ? "50% discount applied" : "70% discount applied"}</h5>
            <ul className="list">
              <div className="secondCards">
                <h3>Payment Plans</h3>
                <div className="secondCard">
                  {cardTow.map((data, index) => (
                    <div className="cardTow" key={index}>
                      <h4>{data?.Pay}</h4>
                      <h6>{data?.Get}</h6>
                      <h2>
                        <span>$</span>{data?.dollar}
                      </h2>
                      <p>{data?.Weeks}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Biweekly 45-minute office hours with Attorney Tareian King, ESQ:"
                      : "Self-paced access:"}
                  </span>
                  {isPro
                    ? "providing personalized legal advice to address your unique concerns."
                    : "Easy-to-follow, pre-recorded video lessons that explain African legal systems, business laws, and immigration rules."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro ? "Weekly live sessions:" : "Clear guidance:"}
                  </span>
                  {isPro
                    ? " For direct, real-time guidance from a legal expert with extensive experience in U.S.-Africa relations, helping you stay ahead of any challenges."
                    : "On how to comply with laws, set up a business, and gain residency in Africa."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Direct Access to the Africa Legal Alliance:"
                      : "Step-by-step assignments:"}
                  </span>
                  {isPro
                    ? " Connect with an elite network of lawyers, realtors, and local experts who will support you through every stage of your relocation or business venture."
                    : "That help you practice what you've learned and apply it to real-life situations."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Comprehensive recorded lessons:"
                      : "Case studies:"}
                  </span>
                  {isPro
                    ? " Covering crucial topics like pre-arrival considerations, choosing the right country, immigration law, family law, business and real estate law, and criminal law—all tailored to Africa."
                    : "Showing real-world examples of people successfully navigating legal challenges in business and residency."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Legal appendix packed with essential resources: "
                      : "Downloadable outlines:"}
                  </span>
                  {isPro
                    ? " Including U.S. embassy contacts, emergency numbers, and key documents, ensuring you're fully equipped for your transition. "
                    : "With important information about legal processes, consulates, embassies, and other key contacts."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Real-world case studies:"
                      : "Helpful planning guides:"}
                  </span>
                  {isPro
                    ? "Showcasing successful strategies used by others, so you can learn from proven examples."
                    : "To walk you through starting a business and moving to Africa, ensuring you’re legally prepared."}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Interactive assignments:" : ""}</span>
                  {isPro
                    ? "To help you apply Africa-specific legal concepts and get real-time feedback from experts."
                    : ""}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Workshops:" : ""}</span>
                  {isPro
                    ? "With local experts from your selected country, offering insider insights to help you navigate the on-the-ground realities of doing business or relocating."
                    : ""}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Tailored solutions:" : ""}</span>
                  {isPro
                    ? "To all your legal needs—whether it's securing residency, buying property, or setting up a business in Africa—ensuring you’re not just prepared, but confident every step of the way."
                    : ""}
                </li>
              </div>
            </ul>
            <Link to="/FormsOne"><button>APPLY NOW</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Primer;
