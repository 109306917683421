import React, { useState, useRef , useEffect} from "react";
import "./SectionThree.css";
import { FaCarBattery, FaPaintBrush } from "react-icons/fa";
import { HiPuzzlePiece } from "react-icons/hi2";
import image1 from "../../assist/imag1.png";
import image2 from "../../assist/imag2.png";
import image3 from "../../assist/imag3.png";
import image4 from "../../assist/imag4.png";
import image5 from "../../assist/imag5.png";
import image6 from "../../assist/imag6.png";
import image7 from "../../assist/imag7.png";
import image8 from "../../assist/imag8.png";
import image9 from "../../assist/imag9.png";

const Program = [
  {
    bacgroundImages : image1,
    icon: <FaPaintBrush />,
    Format: "Format",
    para: "Six-Week Online Program: The course is spread over six weeks, offering flexibility to learn at your own pace while maintaining structure with weekly lessons and live engagement.",
  },
  {
    bacgroundImages : image2,
    icon: <HiPuzzlePiece />,
    Format: "Time Commitment",
    para: "With 3-5 hours of study per week, you can balance your learning alongside personal and professional commitments, making it easy to stay on track.",
  },
  {
    bacgroundImages : image3,
    icon: <FaCarBattery />,
    Format: "Interactive Video Lectures",
    para: "Each module includes in-depth video lectures led by Attorney Tareian King, ESQ, offering detailed explanations of complex legal topics. These lectures are designed to be engaging and easy to follow, even for those without a legal background.",
  },
  {
    bacgroundImages : image4,
    icon: <FaPaintBrush />,
    Format: "Case Studies and Real-Life Example",
    para: "To deepen your understanding, the course features real-life case studies that illustrate common challenges and practical solutions in African residency, business law, and family matters. These examples will give you actionable insights and show how to navigate similar situations successfully.",
  },
  {
    bacgroundImages : image5,
    icon: <HiPuzzlePiece />,
    Format: "Practical Assignments and Tools",
    para: "Throughout the course, you’ll complete practical assignments to apply what you’ve learned. These include creating personalized legal checklists, developing a financial plan for your relocation, and drafting a business registration strategy—tools that ensure you’re prepared for real-world success.",
  },
  {
    bacgroundImages : image6,
    icon: <FaCarBattery />,
    Format: "Office Hours",
    para: "Gain direct access to Attorney King, ESQ during office hours, where you can ask specific questions and receive tailored advice related to your personal situation.",
  },
  {
    bacgroundImages : image7,
    icon: <FaPaintBrush />,
    Format: "Live Weekly Session",
    para: "Participate in live weekly sessions with Attorney King, ESQ, and fellow course participants. These sessions allow you to engage in discussions, ask questions, and deepen your understanding of key legal topics while connecting with your peers.",
  },
  {
    bacgroundImages : image8,
    icon: <HiPuzzlePiece />,
    Format: "Access to Exclusive Resources",
    para: "When you enroll, you’ll receive access to a wealth of additional resources, including downloadable legal documents, templates, and a directory of trusted legal professionals across Africa, giving you the tools and support you need for your journey.",
  },
  {
    bacgroundImages : image9,
    icon: <FaCarBattery />,
    Format: "Personalized Mentorship",
    para: "Benefit from ongoing guidance and mentorship from Attorney-Instructor Tareian King, ESQ, a Global Esquire with a distinguished track record in international law.",
  },
];

const SectionThree = () => {
  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null);
 

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);
  return (
    <div className="SectionThree">
      <h2 className={`fade-in ${isVisible ? "show" : ""}`}
          ref={roadmapRef}>
        <span>COURSE STRUCTURE</span> & EXPERIENCE
      </h2>
      <div className="cards">
        {Program.map((program, index) => (
          <div  className="card" key={index} style={{ backgroundImage: `url(${program.bacgroundImages})` }}>
            <div className="inner">
            <span>{program.icon}</span>
            <h3>{program.Format}</h3>
            <p>{program.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionThree;
