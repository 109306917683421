import React, { useState, useRef , useEffect}  from 'react';
import "./SectionFour.css";


const enroll = [
  {
    heading:"Entrepreneurs and Business Leaders ",
   para:" Entrepreneurs and Business Leaders  looking to launch, expand, or grow their business ventures across Africa, and need a thorough understanding of local legal frameworks, market entry strategies, and compliance requirements." 
  },
  {
    heading: "Investors",
    para:"Investors eager to explore Africa's booming markets, seeking insights on navigating complex investment regulations and maximizing opportunities in real estate, infrastructure, or business sectors."
  },
  {
    heading: "Professionals",
    para:"Professionals interested in relocating to Africa and securing residency or citizenship, needing a comprehensive overview of visa requirements, residency pathways, and the legalities involved in a smooth transition."
  },
  {
    heading: "Members of the African Diaspora",
    para:"Members of the African Diaspora returning home and wanting expert guidance on legal structures for setting up businesses, managing family matters, and ensuring cultural and legal integration."
  },
  {
    heading: "Retirees",
    para:"Retirees planning to settle in Africa, requiring detailed knowledge on residency, healthcare, and financial planning to ensure a seamless and stress-free relocation."
  },
  {
    heading: "Legal and Financial Professionals ",
    para:"Legal and Financial Professionals seeking to expand their practice or offer advisory services in Africa, needing in-depth knowledge of the continent’s legal frameworks and business regulations to assist clients effectively."
  }
]

const SectionFour = () => {

  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);




  return (
    <div className='SectionFour'>
      <h3 className={` fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}><span>WHO SHOULD </span> ENROLL</h3>
      <p className={` fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>This course is designed for professionals, entrepreneurs, investors, and individuals seeking to expand their presence in Africa, as well as those looking to relocate. </p>
      <div className={`cards fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef} >
        {enroll.map((data,index)=>(
          <div className="card" key={index}>
          <h4>{data.heading}</h4>
          <p>{data.para}</p>
        </div>
        ))}
      </div>
    </div>
  )
}

export default SectionFour