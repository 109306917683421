import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Counter = ({ targetNumber, prefix = '', suffix = '' }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000; 
      const increment = targetNumber / (duration / 10); 
      const interval = setInterval(() => {
        start += increment;
        if (start >= targetNumber) {
          clearInterval(interval);
          setCount(targetNumber); 
        } else {
          setCount(Math.round(start));
        }
      }, 10); 
    }
  }, [inView, targetNumber]);

  return (
    <h1 ref={ref}>
      {prefix}
      {count}
      {suffix}
    </h1>
  );
};

export default Counter;
