import React, { useEffect, useRef, useState } from 'react';
import "./LandingPage.css";
import NavBar from '../NavBar/NavBar';
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from './SectionThree/SectionThree';
import SectionFour from "./SectionFour/SectionFour";
import SeactionFives from "./SeactionFive/SeactionFives";
import Esquire from './Esquire/Esquire';
import Testimonials from './Testimonials/Testimonials';
import Primer from "./Primer/Primer";
import SectionFive from "./SectionFive/Footer";
import { Link } from 'react-router-dom';
import { BsFillCaretRightFill, BsFillPauseFill } from "react-icons/bs";

const LandingPage = () => {
  const textInfoRef = useRef(null);
  const videoRef = useRef(null); // Reference for the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track if the video is playing
  const [showIcon, setShowIcon] = useState(true); // State to track icon visibility

  useEffect(() => {
    const textInfoElement = textInfoRef.current;

    // Intersection Observer to trigger animation when text comes into view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          textInfoElement.classList.add('animate-text');
        }
      },
      { threshold: 0.5 } // Adjust this value to control when the animation starts
    );

    observer.observe(textInfoElement);

    return () => observer.unobserve(textInfoElement);
  }, []);

  const handleVideoToggle = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause(); // Pause the video
      setShowIcon(true); // Show the icon again when video is paused
    } else {
      video.play(); // Play the video
      setShowIcon(true); // Show the icon before hiding it

      // Hide the icon after 1 second of video playing
      setTimeout(() => {
        setShowIcon(false);
      }, 1000);
    }
    setIsPlaying(!isPlaying); // Toggle the play/pause state
  };

  const handleMouseEnter = () => {
    setShowIcon(true); // Show the play icon on hover
  };

  const handleMouseLeave = () => {
    if (isPlaying) {
      setTimeout(() => {
        setShowIcon(false); // Hide the play icon after hover when video is playing
      }, 1000);
    }
  };

  return (
    <div className='LandingPage'>
      <NavBar />
      <div className="Door">
        <div className="textInfo" ref={textInfoRef}>
          <Link to="https://globalesquire.com/"><span>Global Esquire Law Firm</span></Link>
          <h5>The Door of Return</h5>
          <h3>A Legal Primer for Business, Residency, and Reconnection in Africa</h3>
          <p>
            Relocating to Africa and starting a business there offers vast
            opportunities, but it also demands careful legal preparation. This
            seven-week comprehensive course is designed to equip you with the
            essential legal knowledge and practical tools needed to ensure a
            smooth transition. Whether you’re planning to move for work,
            entrepreneurship, or retirement, this course will guide you through
            the critical legal aspects of residency and business in Africa.
          </p>
          <Link to="/FormsOne">
            <button style={{ cursor: "pointer" }}>Apply Now</button>
          </Link>
        </div>
        <div className="image"
          onMouseEnter={handleMouseEnter} // Show icon on hover
          onMouseLeave={handleMouseLeave} // Hide icon after hover if playing
        >
          <video className="backgroundVideo" ref={videoRef} loop muted>
            <source
              src={require("../assist/backgroundVideo.mp4")}
              type="video/mp4"
            />
          </video>
          <div
            className={`playIcon ${showIcon ? 'visible' : 'hidden'}`}
            onClick={handleVideoToggle}
          >
            <span>
              {isPlaying ? <BsFillPauseFill /> : <BsFillCaretRightFill />}
            </span>
          </div>
        </div>
      </div>
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SeactionFives />
      <Esquire />
      <Testimonials />
      <Primer />
      <SectionFive />
    </div>
  );
};

export default LandingPage;
