import React, { useState, useRef , useEffect} from "react";
import "./SeactionFive.css";
import dot from "../../assist/dot.png";
import woman from "../../assist/woman.png";
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import Counter from "./Counter";
import { AiOutlineMedium } from "react-icons/ai";
import { Link } from "react-router-dom";

const SeactionFives = () => {
  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);

  return (
    <div className="SeactionFive">
      <img src={dot} alt="dot" className="dot" />
      <div className="sections">
        <div className="imgInfo">
          <h3  className={`fade-in ${isVisible ? "shows" : ""}`}
          ref={roadmapRef}>
            <span>International Attorney</span>-Instructor
          </h3>
          <hr />
        </div>
        <img src={woman} alt="woman" />
        <div className="imgInfo">
          <h2  className={` fade-in ${isVisible ? "shows" : ""}`}
          ref={roadmapRef}>Tareian King, Esq</h2>
          <span className={`span fade-in ${isVisible ? "shows" : ""}`}
          ref={roadmapRef}>founder of Global Esquire,</span>
          <Link to="https://www.globalesquire.com" target="_blank" rel="noopener noreferrer"><p className={` fade-in ${isVisible ? "show" : ""}`}
          ref={roadmapRef}>www.globalesquire.com</p></Link>
        </div>
      </div>
      <div className="counters">
        <div className="countersCard">
          <Counter targetNumber={400} suffix={<FaPlus />} />
          <h3>Clients Represented</h3>
          <p>
            Total projects successfully delivered across various industries.
          </p>
        </div>
        <div className="countersCard">
          <Counter targetNumber={100} suffix={<FaPlus />} />
          <h3>Companies formed between US, Africa, and France</h3>
        </div>
        <div className="countersCard">
          <Counter targetNumber={386} prefix="$" suffix={<AiOutlineMedium />} />
          <h3>In Legal Management of TECH Investments in Africa</h3>
        </div>
      </div>
    </div>
  );
};

export default SeactionFives;
